import PropTypes from 'prop-types';
import NoResults from '../../img/noResults.svg';
import './index.scss';
// remove custom styles once all tables where used have been rebuilt
const EmptyTable = ({type, tableType}) => {
  return (
    <div className={`empty-table ${type === 'none-assigned' ? 'empty-table--no-results' : ''}`}>
      <img src={NoResults} alt="No results found" className="empty-table__icon" />
      <div className="empty-table__line-1">
        {type === 'filter'
          ? `Uh oh! We couldn’t find any ${tableType}.`
          : `You have no assigned ${tableType}!`
        }
      </div>
      <div className="empty-table__line-2">
        {type === 'filter'
          ? 'Looks like there’s no results that fit your criteria.'
          : 'Please come back later.'
        }
      </div>
    </div>
  )
}

EmptyTable.propTypes = {
  type: PropTypes.oneOf(['filter', 'none-assigned']),
}

export default EmptyTable;