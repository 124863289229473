import { Admin } from "../../../../src/fmo-api-sdk/";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useHistory, useParams } from "react-router-dom";
import AddLogModal from "../add-log";
import PuffLoader from "react-spinners/PuffLoader";
import ModalHeader from "../../modal-components/modal-header";
import AddJobModal from "../add-job";
import Button from "../../button";
import LinkIcon from "../../../img/accountDetails.svg"
import Tag from "../../tag";
import GreenTickFilled from "../../../img/greenTickFilled.svg";
import Timer from "../../../img/timer.svg"
import TimelineEntry from "./timeline-entry";
import moment from "moment";
import { getRole, AUTH_ADMIN, AUTH_INVENTORY } from "../../../app/auth";
import { useSelector } from "react-redux";
import RankTag from "../../rank-tag";

export type JobHistoryModalData = {
  currentJob: any,
}

const JobHistoryModal = ({ data = {} as JobHistoryModalData, autoGenerated, setClosed, timeline=false, ...props}) => {
  const history = useHistory();
  const authToken = useSelector((state: any) => state.auth.token);
  const userRole = authToken && getRole(authToken);
  const navigate = url => history.push(url);

  const [jobLogs, setJobLogs] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>('Timeline');

  const tabs = ['Timeline', 'Add Log'];
  const isMobile = window.innerWidth < 768;
  const jobComplete = data.currentJob?.status === "Completed" || data.currentJob?.type.includes("complete");
  const { id }: { id: string } = useParams();

  if (userRole === AUTH_ADMIN || userRole === AUTH_INVENTORY) {
    tabs.push('Update Job');
  }

  // fix to remove .currentJob from data?

  useEffect(() => {
    if ((data.currentJob ?? null) === null) return;
    setLoading(true);
    fetchJobLogs();
  }, [data.currentJob]);

  const fetchJobLogs = async () => {
      setActiveTab('Timeline');

      Admin.getJobLogs(data.currentJob.job_id, {cache: false})
          .then(jobLogs => {
              var completeLogIndex = jobLogs.findIndex(log => log.activity_log_reason === 'Job Complete Log');

              if (completeLogIndex !== -1 && completeLogIndex !== jobLogs.length -1) {
                var test = jobLogs.splice(completeLogIndex, 1);
                jobLogs.splice(jobLogs.length, 0, test[0]);
              }

              setJobLogs(jobLogs);
              setLoading(false);
          });
  }

  const closeHistoryModal = (forceUpdate = false) => {
    setClosed(forceUpdate);
  }

  const trimSurname = fullName => {
    if (fullName) return fullName.substring(0, fullName.indexOf(' '));
  }

  // Render the timeline
  const renderTimeline = () => {
    const dates = [moment(data?.currentJob?.created).format('DD/MM/YYYY')];

    if (loading) {
      return (
        <div className="spinner-border" style={{ height: "234px", paddingBottom: "40px" }}>
          <PuffLoader
            size={75}
            color={"#D82B31"}
            loading={true}
          />
        </div>
      )
    }

    return (
      <>
        <div className="modal__timeline modal-scrollbar">
          {!jobLogs?.some(item => item.activity_log_reason.includes('Job Created')) && (
            <TimelineEntry
              createdBy={autoGenerated ? 'auto' : trimSurname(data?.currentJob?.created_by_username ?? data?.currentJob?.created_by)}
              assignee={data?.currentJob?.agent_first_name ?? trimSurname(data?.currentJob?.assigned_to)}
              date={data?.currentJob?.created}
              description={data?.currentJob?.description}
              priority={data?.currentJob?.priority}
              type="createdOld"
            />
          )}

          {jobLogs?.map((item, key) => {
            let itemData;
            try {
              itemData = JSON.parse(item.activity_log_report);
            } catch (error) {
              itemData = false;
            }

            var logDateTime = moment(item.activity_log_added).format('DD/MM/YYYY');
            var newDate;

            if (!dates.includes(logDateTime)) {
              newDate = true;
              dates.push(logDateTime);
            }
            
            return (
              <TimelineEntry
                key={key}
                createdBy={item.created_by && trimSurname(item.created_by)}
                assignee={itemData?.assigned_to?.new}
                date={(item.activity_log_reason.includes('Job Created') || newDate) && item.activity_log_added}
                description={itemData ? itemData.description : item.activity_log_report}
                priority={itemData?.priority?.new}
                type={
                  item.activity_log_reason.includes('Job Created')
                    ? 'created'
                    : item.activity_log_reason.includes('Job Updated')
                      ? 'updated'
                      : item.activity_log_reason.includes('Job Complete Log')
                        ? 'completed'
                        : undefined
                } />
            )
          })}
        </div>

        {data?.currentJob?.wholesaler_id && (
          <div className="modal__submit-wrapper modal__submit-wrapper--timeline">
            <Button type="secondary" buttonText="Shop Overview" icon={LinkIcon} iconAltText="Shop overview link" onClick={() => navigate(`/overview/${data.currentJob?.wholesaler_id}`)} grow />
          </div>
        )}
      </>
    )
  }

  const getTags = () => {
    var size = isMobile ? 'medium' : 'large';

    if (jobComplete) {
      return (
        <>
          {!timeline && <RankTag rank={data?.currentJob?.wholesaler_rank_name} square large={!isMobile} />}
          <Tag text={'Done'} size={size} icon={GreenTickFilled} altText="Completed icon" type='completed' />
        </>
      )
    }
    return (
      <>
        <Tag size={size} priority={data?.currentJob?.priority.toLowerCase()} noIcon={isMobile && !timeline} />
        {!timeline && <RankTag rank={data?.currentJob?.wholesaler_rank_name} square large={!isMobile} />}
        {isMobile && !timeline
          ? <Tag text='Pending' size={size} type='highlight' />
          : <Tag text='Pending' size={size} icon={Timer} altText="Pending icon" type='highlight' />
        }
      </>
    )
  }

  return (
    <Modal {...props} className={`modal ${jobComplete ? 'modal--auto-height' : ''}`} overlayClassName="overlay">
      <ModalHeader title="Job History" setClosed={closeHistoryModal} shopId={data?.currentJob?.wholesaler_id} tags={getTags} />

      {!jobComplete && (
        <div className="modal__tab-wrapper">
          {tabs.map(tab => (
            <div className={`modal__tab ${tab === activeTab && 'modal__tab--active'}`} onClick={() => setActiveTab(tab)}>{tab}</div>
          ))}
        </div>
      )}

      {activeTab === 'Timeline' ? (
        renderTimeline()
      ) : activeTab === 'Add Log' ? (
        <AddLogModal
          jobHistory
          data={{
            shopId: id ?? data?.currentJob?.wholesaler_id,
            shopName: data.currentJob?.wholesaler_name,
            jobId: data.currentJob?.job_id
          }}
          setClosed={closeHistoryModal}
        />
      ) : (
        <AddJobModal
          jobHistory
          data={{
            shopId: id ?? data?.currentJob?.wholesaler_id,
            shopName: data.currentJob?.wholesaler_name,
            jobId: data.currentJob?.job_id
          }}
          setClosed={closeHistoryModal}
        />
      )}
    </Modal>
  )
}


export default JobHistoryModal;
