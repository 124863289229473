import Button from "../../button";
import DeleteIconRed from '../../../img/icons/deleteIconRed.svg';
import DeleteIconWhite from '../../../img/icons/deleteIconWhite.svg';
import FilterIcon from '../../../img/icons/filterIcon.svg';
import FilterIconLight from '../../../img/icons/filterIconLight.svg';

const FilterButtons = ({filtersEnabled, openFilterModal, clearFilters, disabled}) => {
  const isMobile = window.innerWidth < 768;

  return (
    <>
      {filtersEnabled &&
        <Button
          onClick={clearFilters}
          icon={isMobile ? '' : DeleteIconRed}
          hoverIcon={isMobile ? '' : DeleteIconWhite}
          iconAltText={isMobile ? '' : 'Clear filters'}
          condensed
          type="danger"
          buttonText={isMobile ? 'Clear' : 'Clear All'} />
      }
    
      <Button
        buttonText={isMobile ? '' : 'Apply Filters'}
        onClick={openFilterModal}
        type='tertiary'
        condensed
        icon={disabled ? FilterIconLight : FilterIcon}
        iconAltText={'Open prospect filter'}
        disabled={disabled}
        variant={isMobile ? 'square' : undefined} />
    </>
  )
}

export default FilterButtons