import PropTypes from 'prop-types';
import './index.scss';
import NoRankIcon from '../../img/icons/rank-icons/no-rank16.svg';
import NoRankIcon24 from '../../img/icons/rank-icons/no-rank24.svg';
import NewShopIcon from '../../img/icons/rank-icons/new-shop16.svg';
import NewShopIcon24 from '../../img/icons/rank-icons/new-shop24.svg';
import BronzeIcon from '../../img/icons/rank-icons/bronze16.svg';
import BronzeIcon24 from '../../img/icons/rank-icons/bronze24.svg';
import SilverIcon from '../../img/icons/rank-icons/silver16.svg';
import SilverIcon24 from '../../img/icons/rank-icons/silver24.svg';
import GoldIcon from '../../img/icons/rank-icons/gold16.svg';
import GoldIcon24 from '../../img/icons/rank-icons/gold24.svg';
import PlatinumIcon from '../../img/icons/rank-icons/platinum16.svg';
import PlatinumIcon24 from '../../img/icons/rank-icons/platinum24.svg';
import DiamondIcon from '../../img/icons/rank-icons/diamond16.svg';
import DiamondIcon24 from '../../img/icons/rank-icons/diamond24.svg';
import EliteIcon from '../../img/icons/rank-icons/elite16.svg';
import EliteIcon24 from '../../img/icons/rank-icons/elite24.svg';

type Props = {
  rank?: string
} & (
  {
    pill?: never
    large?: boolean
    square?: boolean
  } | {
    pill: boolean
    large?: never
    square?: never
  }
)

const RankTag = ({ rank, large, square, pill }: Props) => {
  var realRank = rank ?? 'New Shop';
  var formattedRank = realRank?.toLowerCase().replace(' ', '-');
  let icon;

  if (formattedRank === 'no-rank') icon = pill ? NoRankIcon24 : NoRankIcon;
  else if (formattedRank === 'new-shop') icon = pill ? NewShopIcon24 : NewShopIcon;
  else if (formattedRank === 'bronze') icon = pill ? BronzeIcon24 : BronzeIcon;
  else if (formattedRank === 'silver') icon = pill ? SilverIcon24 : SilverIcon;
  else if (formattedRank === 'gold') icon = pill ? GoldIcon24 : GoldIcon;
  else if (formattedRank === 'platinum') icon = pill ? PlatinumIcon24 : PlatinumIcon;
  else if (formattedRank === 'diamond') icon = pill ? DiamondIcon24 : DiamondIcon;
  else if (formattedRank === 'elite') icon = pill ? EliteIcon24 : EliteIcon;

  return (
    <div className={`rank-tag rank-tag--${formattedRank} ${large ? 'rank-tag--large' : ''} ${square ? 'rank-tag--square' : ''} ${pill ? 'rank-tag--pill' : ''}`}>
      <img src={icon} alt='Shop Rank Icon' />
      {!square && realRank}
    </div>
  )
}

RankTag.propTypes = {
  type: PropTypes.oneOf(['no-rank', 'new-shop', 'bronze', 'silver', 'gold', 'platinum', 'diamond', 'elite']),
}

export default RankTag