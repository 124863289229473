import React, {useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import './index.scss';
import {unescape, filter, orderBy, get} from "lodash";

import {useHistory, useLocation} from "react-router-dom";

import Facebook from "../../img/facebook.svg";
import Google from "../../img/google.svg";
import Sticker from "../../img/sticker.svg";

import FacebookOn from "../../img/facebookon.svg";
import GoogleOn from "../../img/googleon.svg";
import StickerOn from "../../img/stickeron.svg";

import xLight from "../../img/xLight.svg";
import Divider from "../../img/divider.svg";
import Expand from "../../img/expand.svg";
import ExpandPurple from "../../img/expandPurple.svg";
import ExpandRed from "../../img/expandRed.svg";
import ExpandGrey from "../../img/expandGrey.svg";
import Collapse from "../../img/collapse.svg";
import CollapsePurple from "../../img/collapsePurple.svg";
import CollapseRed from "../../img/collapseRed.svg";
import CollapseGrey from "../../img/collapseGrey.svg";
import FavouriteActive from "../../img/favouriteActive.svg";
import FavouriteInactive from "../../img/favouriteInactive.svg";
import FilterIcon from '../../img/icons/filterIcon.svg';
import FilterIconLight from '../../img/icons/filterIconLight.svg';
import RankIcon from "../../img/icons/rank-icons/rank-icon.svg";

import PuffLoader from "react-spinners/PuffLoader";
import moment from "moment";
import useWindowSize from "../../app/helper/screen";

import {Admin} from '../../fmo-api-sdk';
import NavigationContext from "../../NavigationContext";
import ShopFilter from "../modals/shop-filter";
import Disclaimer from "../disclaimer/disclaimer";
import isProblemShop from '../../app/helper/reports';
import { getUserId } from '../../app/auth';
import Counter from '../header-components/counter';
import Button from '../button';
import AppliedFilterTag from '../applied-filter-tag';
import SearchButton from '../header-components/search-button';
import SearchField from '../header-components/search-field';

import NoRankIcon24 from '../../img/icons/rank-icons/no-rank24.svg';
import NewShopIcon24 from '../../img/icons/rank-icons/new-shop24.svg';
import BronzeIcon24 from '../../img/icons/rank-icons/bronze24.svg';
import SilverIcon24 from '../../img/icons/rank-icons/silver24.svg';
import GoldIcon24 from '../../img/icons/rank-icons/gold24.svg';
import PlatinumIcon24 from '../../img/icons/rank-icons/platinum24.svg';
import DiamondIcon24 from '../../img/icons/rank-icons/diamond24.svg';
import EliteIcon24 from '../../img/icons/rank-icons/elite24.svg';
import EmptyTable from '../empty-table';

function AgentShopList(
    {
        title,
        showRegion = false,
        inDeclineOnly = false,
        setLeftHeaderSlot,
        setRightHeaderSlot
    }: {
        title?: string,
        showRegion?: boolean,
        inDeclineOnly?: boolean
        setLeftHeaderSlot?: any
        setRightHeaderSlot?: any
    }
) {

    // route params and history
    const navigationContext = useContext(NavigationContext);
    var {searchTerm, searchType, updateSearch, filterIsOpen, filterClose} = navigationContext;

    const location = useLocation();
    const {state = {}} = location;
    const {agent = {}} = state as any;
    const history = useHistory();
    const token = useSelector((state: any) => state.auth.token);
    const userId = getUserId(token)

    const isMobile = window.innerWidth < 768;
    const isMyRegion = title === 'My Region';

    const [shops, setShopsState] = useState<Array<Admin.AdminShop>>([]);
    const [filtered, setFiltered] = useState<Array<Admin.AdminShop>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [sorter, setSorter] = useState('wholesaler_name');
    const [sortDir, setSortDir] = useState<string>("asc");
    const [shopFilterData, setShopFilterData] = useState<any>(JSON.parse(localStorage.getItem(isMyRegion ? 'myRegionFilters' : 'myProblemShopsFilters') ?? '{}'));
    const [favouriteShops, setFavouriteShops] = useState<Array<string>>([]);
    const [filterIsOpenLocal, setFilterIsOpenLocal] = useState<boolean>(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [filtersEnabled, setFiltersEnabled] = useState(false);

    //hide title when there's filtered content on mobile devices
    const size = useWindowSize();
    const renderTitle = function () {
        const storedFilters = Object.values((JSON.parse(localStorage.getItem(isMyRegion ? 'myRegionFilters' : 'myProblemShopsFilters') ?? '{}')))
        const hasFilters = !!storedFilters.find((what: any) => ![-1, '', false].includes(what));

        if (size.width !== undefined && size.width >= 768) {
            return (<div className="table-title">{titleText}</div>);
        }
        if ( searchTerm === "" && !hasFilters ) {
            return (<div className="table-title">{titleText}</div>);
        }
        return null;
    }

    //prevent scrolling when modal is active
    const preventScrolling = function (filterIsOpen) {
        let overflowingComponent = document.getElementById("dashboard-actual");
        if (filterIsOpen) {
            document.body.classList.add("no-scroll");
            overflowingComponent?.classList.add("jitter-fix");
        } else {
            document.body.classList.remove("no-scroll");
            overflowingComponent?.classList.remove("jitter-fix");
        }
    }

    preventScrolling(filterIsOpen);

    const getFavourites = async() => {
      await Admin.getFavourites(userId)
        .then(res => {
          setFavouriteShops(res.shop_favourites);
        })
        .catch(error => console.log(error));
    }

    const handleFavouriteclick = async(shopId) => {
        if (favouriteShops.includes(shopId)) {
            await Admin.removeFavourite(shopId)
              .then(() => setFavouriteShops(favouriteShops.filter(favourite => favourite !== shopId)))
              .catch(error => alert(error));
        } else {
            await Admin.addFavourite(shopId)
              .then(() => setFavouriteShops(favouriteShops.concat(shopId)))
              .catch(error => alert(error));
        }
    };

    const [expandedOrders, setExpandedOrders] = useState<boolean>(true);
    const [expandedEposOrders, setExpandedEposOrders] = useState<boolean>(false);
    const [expandedShopInfo, setExpandedShopInfo] = useState<boolean>(false);
    const [expandedNumbers, setExpandedNumbers] = useState<boolean>(false);
    const [expandedMarketing, setExpandedMarketing] = useState<boolean>(false);
    const [expandedSms, setExpandedSms] = useState<boolean>(false);

    // Table sorting
    const sortTable = (head) => {
        let newSortDir = 'asc';
        if (sorter === head) {
            newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
        }

        setSortDir(newSortDir);
        setSorter(head);
    };

    // Initial data load
    useEffect(() => {
        (async () => {
            const check = await Admin.isPermitted(userId);
            if (!check) return setLoading(false);

            let data;
            const agentToGet = agent.agent_id || userId;

            if (showRegion) {
                data = await Admin.getShopsManagedByAgent(agentToGet)
                  .catch(res => {
                    setLoading(false);
                  });
            } else {
                data = await Admin.getShopsByAgent(agentToGet)
                .catch(() => {
                  setLoading(false);
                });
            }

            // Compute some required values from the data
            for (const shop of data) {
                // @ts-ignore
                shop.orders.last_month_trend = shop.orders.last_month - shop.orders.prev_month;
            }

            // Set screen state
            setShopsState(data);
            // setFiltered(data);
            setLoading(false);
        })()

        getFavourites();

        const onScroll = () => {
            if (window.scrollY > 250) localStorage.setItem(isMyRegion ? 'myRegionScrollPos' : 'myProblemShopsScrollPos', window.scrollY.toString());
        };

        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    useEffect(() => {
        if (!loading) window.scrollTo({top: parseInt(localStorage.getItem(isMyRegion ? 'myRegionScrollPos' : 'myProblemShopsScrollPos') ?? '0')});
    }, [loading]);

    useEffect(() => {
      setLeftHeaderSlot && setLeftHeaderSlot(<Counter counterData={(!filtersEnabled && searchTerm === '') || isMobile ? filtered?.length : `${filtered?.length} / ${shops?.length}`} />);

      setRightHeaderSlot && setRightHeaderSlot(
        <>
          <Button
            buttonText={isMobile ? '' : 'Apply Filters'}
            onClick={setFilterIsOpenLocal}
            type='tertiary'
            condensed
            icon={!shops.length ? FilterIconLight : FilterIcon}
            iconAltText={'Open prospect filter'}
            disabled={!shops.length}
            variant={isMobile ? 'square' : undefined} />

          {isMobile
            ? <SearchButton onClick={() => setSearchOpen(!searchOpen)} />
            : (
                <SearchField
                  placeholder='Search name or postcode'
                  onSearch={val => updateSearch(val, '')}
                  disabled={!filtered.length} />
            )
          }
        </>
      );
    }, [filtered, filtersEnabled, searchTerm, searchOpen])

    function getDeviceType(deviceData) {
        const hasT2s = deviceData.some(device => device.device_type === 'epos');
        const hasV2 = deviceData.some(device => device.device_type === 'smartlink');

        if (hasT2s) return 'T2s'
        else if (hasV2) return 'V2'
        else return 'None'
    }

    function getRank(rankName, ordering=false) {
      if (!rankName) return ordering ? '6' : NewShopIcon24;
      else if (rankName === 'Bronze') return ordering ? '5' : BronzeIcon24;
      else if (rankName === 'Silver') return ordering ? '4' : SilverIcon24;
      else if (rankName === 'Gold') return ordering ? '3' : GoldIcon24;
      else if (rankName === 'Platinum') return ordering ? '2' : PlatinumIcon24;
      else if (rankName === 'Diamond') return ordering ? '1' : DiamondIcon24;
      else if (rankName === 'Elite') return ordering ? '0' : EliteIcon24;
      else return ordering ? '7' : NoRankIcon24;
    }

    // Filter data once loaded or filter changes
    useEffect(() => {
        let filtered = shops;

        if (searchTerm !== '') {
              filtered = filter(shops, ob => ob.wholesaler_name.toLowerCase().includes(searchTerm.toLowerCase()) || ob.wholesaler_postcode.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        const {hideGoogle = false, hideFacebook = false, hideSticker = false, onlyShowTemp = -1, onlyShowDevice = '', onlyFavourites = false} = shopFilterData;

        if (hideGoogle) filtered = filter(filtered, ob => !ob.promotion.google);
        if (hideFacebook) filtered = filter(filtered, ob => !ob.promotion.facebook);
        if (hideSticker) filtered = filter(filtered, ob => !ob.promotion.sticker);

        if (inDeclineOnly) filtered = filter(filtered, ob => {
            return isProblemShop(ob)
        });

        if (onlyShowDevice) {
            // @ts-ignore
            filtered = filtered.filter(shop => getDeviceType(shop.machine?.devices) === onlyShowDevice);
        }

        if (onlyFavourites) {
          filtered = filtered.filter(shop => favouriteShops.includes(shop.wholesaler_id.toString()))
        }

        if (onlyShowTemp >= 0) {
            switch (onlyShowTemp) {
                case 100:
                    // Filter by < 3 months old
                    filtered = filter(filtered, ob => moment(ob.info.sign_up_date) > moment().subtract(3, 'M'));
                    break;

                case 101:
                    // Filter by not left us or cancelled
                    filtered = filter(filtered, ob => ![5, 7].includes(parseInt(ob.info.wholesaler_status)));
                    break;

                case 102:
                    // Filter by NOT beaten their previous month
                    filtered = filter(filtered, ob => ob.orders.prev_month >= ob.orders.this_month);
                    break;

                case 103:
                    // Filter out >12 months old
                    filtered = filter(filtered, ob => moment(ob.info.sign_up_date) > moment().subtract(1, 'years'));
                    break;

                default:
                    // Filter by wholesaler status
                    filtered = filter(filtered, ob => parseInt(ob.info.wholesaler_status) === onlyShowTemp);
            }
        }

        setFiltered(filtered);
    }, [shops, searchTerm, searchType, shopFilterData])


    // Table colors
    const disallowedStatusCodes = [0, 5, 6, 7];

    function getStatus(mode, statusCode) {
      if (mode === 'tableCell') {
        if (disallowedStatusCodes.includes(statusCode)) return 'Offline';
        return 'Online';
      } else {
        if (disallowedStatusCodes.includes(statusCode)) return 'offline';
      }
    }

    const getTextColor = (status: number): (string | undefined) => {
        if (status === 2 || status === 4) return "#43A564";
        if (status === 0 || status === 3 || status === 5 || status === 7) return "#D82B31";
        if (status === 6) return "#5F09EB";
        return "#2f2621";
    }

    const getPackageColor = (type) => {
        if (type === "FREE") return "#302A2A";
        if (type === "%") return "#302A2A"
        return "#2f2621";
    }

    // Go to overview
    const openOverview = (shop: any) => {
        history.push(`/overview/${shop.wholesaler_id}`, {shop});
    }

    const renderTableShopInfoHeaders = () => {
        if (!expandedShopInfo) {
            return null;
        }

        return (
            <>
                <th onClick={() => sortTable('info.signed_by')} className="table_shop_info sticky-row">
                    Signed By
                </th>
                <th onClick={() => sortTable('info.commission')} className="table_shop_info sticky-row">
                    Commission
                </th>
                <th className="table_shop_info sticky-row">
                    Package
                </th>
                <th onClick={() => sortTable('info.sign_up_date')} className="table_shop_info sticky-row">
                    Sign Up Date
                </th>
                <th onClick={() => sortTable('info.online_date')} className="table_shop_info sticky-row">
                    Online Since
                </th>
            </>
        );
    }

    const renderTableShopInfoData = (v) => {
        if (!expandedShopInfo) {
            return null;
        }

        return (
            <>
                <td className="table_shop_info">{v.info.signed_by}</td>
                <td className="table_shop_info">{v.info.commission}</td>
                <td className="table_shop_info" style={{
                    fontWeight: 600,
                    fontSize: v.info.package_type === "%" ? 20 : 14,
                    color: getPackageColor(v.info.package_type)
                }}>{v.info.package_type}</td>
                <td className="table_shop_info">{moment(v.info.sign_up_date).isValid() ? moment(v.info.sign_up_date).format('DD/MM/YY') : 'N/a'}</td>
                <td className="table_shop_info">{moment(v.info.online_date).isValid() ? moment(v.info.online_date).format('DD/MM/YY') : 'N/a'}</td>
            </>
        )
    }

    const renderTableOrdersHeaders = () => {
        if (!expandedOrders) {
            return null;
        }

        return (
            <>
                <th onClick={() => sortTable('orders.today')} className='table_orders sticky-row'>
                    Today
                </th>
                <th onClick={() => sortTable('orders.this_week')} className='table_orders sticky-row'>
                    This Week
                </th>
                <th onClick={() => sortTable('orders.this_month')} className='table_orders sticky-row'>
                    {moment().format("MMM")}
                </th>
                <th onClick={() => sortTable('orders.last_month_trend')} className='table_orders sticky-row'>
                    MoM
                </th>
                <th onClick={() => sortTable('YoY')} className='table_orders sticky-row'>
                    YoY
                </th>
                <th onClick={() => sortTable('orders.last_month')} className='table_orders sticky-row'>
                    {moment().subtract(1, 'M').format("MMM")}
                </th>
                <th onClick={() => sortTable('orders.prev_month')} className='table_orders sticky-row'>
                    {moment().subtract(2, 'M').format("MMM")}
                </th>
                <th onClick={() => sortTable('orders.prev_prev_month')} className='table_orders sticky-row'>
                    {moment().subtract(3, 'M').format("MMM")}
                </th>
                <th onClick={() => sortTable('orders.last_month_last_year')} className='table_orders sticky-row'>
                    {moment().subtract(13, 'months').format("MMM YY")}
                </th>
            </>
        );
    }

    const renderTableOrdersFooters = (today, thisWeek, thisMonth, trend, YoY, prevMonth, prevPrevMonth, prevPrevPrevMonth, totalOrdersLastYearLastMonth) => {
        if (!expandedOrders) {
            return null;
        }

        return (
            <>
                <td>{today}</td>
                <td>{thisWeek}</td>
                <td>{thisMonth}</td>
                <td>{trend}</td>
                <td>{YoY}</td>
                <td>{prevMonth}</td>
                <td>{prevPrevMonth}</td>
                <td>{prevPrevPrevMonth}</td>
                <td>{totalOrdersLastYearLastMonth}</td>
            </>
        );
    }

    const renderTableEposOrdersFooters = (totalInstoreLastMonth, totalPhoneThisMonth, totalPhoneLastMonth, totalCardThisMonth, totalCardLastMonth) => {
        if (!expandedEposOrders) {
            return null;
        }

        return (
            <>
                <td>{totalInstoreLastMonth}</td>
                <td>{totalPhoneThisMonth}</td>
                <td>{totalPhoneLastMonth}</td>
                <td>{totalCardThisMonth}</td>
                <td>{totalCardLastMonth}</td>
            </>
        );
    }

    const renderShopInfoFooters = () => {
        if (expandedShopInfo) return (
            <>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </>
        );
        return <td></td>;
    }

    const renderEposState = (shopData, sorting?) => {
        var lastHeard = shopData.machine.caller_id_last_heard;
        var deviceType = getDeviceType(shopData.machine.devices);
        var highlightRed = 'status-green';
        var cellContent;

        if (deviceType === 'T2s' || deviceType === 'Both') {
            if (!lastHeard) {
                cellContent = 'Disconnected';
                highlightRed = 'status-red disconnected';
            } else if (lastHeard?.customer_date_added) {
                if (moment(lastHeard.customer_date_added) < moment().subtract(2, 'd')) {
                //   cellContent = 'Over 2 days';
                cellContent = moment(lastHeard.customer_date_added).format('DD/MM/YYYY');
                  highlightRed = 'status-red';
                } else cellContent = 'Online';
            }
        } else {
          cellContent = 'N/a'
          highlightRed = 'status-disabled'
        };

        if (sorting) return cellContent;
        return <td className={highlightRed}>{cellContent}</td>
    }

    function getDotClass(statusCode) {
      var wholesalerStatus = parseInt(statusCode)
      if (wholesalerStatus === 1) {
        return "dot dot--green";
      } else if (wholesalerStatus === 2) {
        return "dot dot--blue";
      } else if (wholesalerStatus === 2) {
        return "dot dot--red";
      } else {
        return "dot dot--amber";
      }
    };

    const renderTableOrdersData = (v) => {
        if (!expandedOrders) {
            return null;
        }

        const {today = 0, this_week = 0, this_month = 0, last_month = 0, prev_month = 0, prev_prev_month = 0, last_month_trend = 0, last_month_last_year = 0} = v.orders;
        const last_month_status_class = last_month >= prev_month ? "status-green" : "status-red";
        const prev_month_status_class = prev_month >= prev_prev_month ? "status-green" : "status-red";
        let this_month_status_class = this_month >= last_month ? "status-green" : "";

        function getYoyClass() {
            let returnedClass = 'table_orders'

            if (last_month < last_month_last_year * 0.75) return returnedClass += ' status-red-dark';
            if (last_month < last_month_last_year * 0.9) return returnedClass += ' status-red';
            if (last_month < last_month_last_year) return returnedClass += ' status-amber';
            if (last_month < last_month_last_year * 1.25) return returnedClass += ' status-green';
            if (!(last_month < last_month_last_year * 1.25)) return returnedClass += ' status-green-dark';
        }

        // Reset colours for this month if the current count is 0
        // As confirmed by Matthew 24/01/22 @ ~09:54
        if (this_month === 0) {
            this_month_status_class = "";
        }

        // @ts-ignore
        const ordersLastMonthTrend = last_month_trend > 0 ? `+${last_month_trend}` : last_month_trend;
        const ordersYoYTrend = last_month - last_month_last_year;

        return (
            <>
                <td className="table_orders">{today}</td>
                <td className="table_orders">{this_week}</td>
                <td className={`table_orders ${this_month_status_class}`}>{this_month}</td>
                <td className={`table_orders ${last_month_status_class}`}>{ordersLastMonthTrend}</td>
                <td className={`table_orders ${ordersYoYTrend >= 0 ? "status-green" : "status-red"}`}>{ordersYoYTrend > 0 ? '+' : ''}{ordersYoYTrend}</td>
                <td className={`table_orders ${last_month_status_class}`}>{last_month}</td>
                <td className={`table_orders ${prev_month_status_class}`}>{prev_month}</td>
                <td className="table_orders">{prev_prev_month}</td>
                <td className={getYoyClass()}>{last_month_last_year}</td>
            </>
        );
    }

    const renderTableEposOrdersHeaders = () => {
        if (!expandedEposOrders) {
            return null;
        }

        return (
            <>
                <th onClick={() => sortTable('orders_epos.instore_last_month')} className="table_epos_orders sticky-row">
                    <div className="header-badge header-badge--secondary-badge white-badge">
                        <div className="badge-text">In-Store</div>
                    </div>
                    Last Month
                </th>
                <th onClick={() => sortTable('orders_epos.phone_this_month')} className="table_epos_orders sticky-row">
                    <div className="header-badge header-badge--secondary-badge white-badge">
                        <div className="badge-text">Phone</div>
                    </div>
                    This Month
                </th>
                <th onClick={() => sortTable('orders_epos.phone_last_month')} className="table_epos_orders sticky-row">
                    <div className="header-badge header-badge--secondary-badge white-badge">
                        <div className="badge-text">Phone</div>
                    </div>
                    Last Month
                </th>
                <th onClick={() => sortTable('orders_epos.card_terminal_payments_this_month')} className="table_epos_orders sticky-row">
                    <div className="header-badge header-badge--secondary-badge white-badge">
                        <div className="badge-text">Card Reader</div>
                    </div>
                    This Month
                </th>
                <th onClick={() => sortTable('orders_epos.card_terminal_payments_last_month')} className="table_epos_orders sticky-row">
                    <div className="header-badge header-badge--secondary-badge white-badge">
                        <div className="badge-text">Card Reader</div>
                    </div>
                    Last Month
                </th>
            </>
        );
    }

    const renderTableEposOrdersData = (v) => {
        if (!expandedEposOrders) {
            return null;
        }

        const cardReaderSeen = v.machine.card_reader_last_seen || v.orders_epos.card_terminal_payments_this_month || v.orders_epos.card_terminal_payments_last_month;

        return (
            <>
                <td className="table_numbers">{v.orders_epos.instore_last_month}</td>
                <td className="">{v.orders_epos.phone_this_month}</td>
                <td className="">{v.orders_epos.phone_last_month}</td>
                <td className={cardReaderSeen ? '' : 'status-disabled'}>{cardReaderSeen ? v.orders_epos.card_terminal_payments_this_month : 'N/a'}</td>
                <td className={cardReaderSeen ? '' : 'status-disabled'}>{cardReaderSeen ? v.orders_epos.card_terminal_payments_last_month : 'N/a'}</td>
            </>
        )
    }

    const renderTableNumbersHeaderColumns = () => {
        if (!expandedNumbers) {
            return null;
        }

        return (
            <>
                <th onClick={() => sortTable('numbers.this_month')} className="table_numbers redText sticky-row">
                    This Month
                </th>
                <th onClick={() => sortTable('numbers.last_month')} className="table_numbers redText sticky-row">
                    Last Month
                </th>
                <th onClick={() => sortTable('info.customers')} className="table_numbers redText sticky-row">
                    Customers
                </th>
                <th onClick={() => sortTable('numbers.caller_id')} className="table_numbers redText sticky-row">
                    Caller ID
                </th>
                <th onClick={() => sortTable('numbers.added_manually')} className="table_numbers redText sticky-row">
                    Added Manually
                </th>
            </>
        );
    }

    const renderTableNumbersDataColumns = (v) => {
        if (!expandedNumbers) {
            return null;
        }

        return (
            <>
                <td className="table_numbers">{v.numbers.this_month}</td>
                <td className="table_numbers">{v.numbers.last_month}</td>
                <td className="table_numbers">{v.info.customers}</td>
                <td className="table_numbers">{v.numbers.caller_id}</td>
                <td className="table_numbers">{v.numbers.added_manually}</td>
            </>
        );
    }

    const renderTableNumbersFooterColumns = (thisMonth, lastMonth, total, callerId, manuallyAdded) => {
        if (!expandedNumbers) {
            return null;
        }

        return (
            <>
                <td>{thisMonth}</td>
                <td>{lastMonth}</td>
                <td>{total}</td>
                <td>{callerId}</td>
                <td>{manuallyAdded}</td>
            </>
        );
    }

    const renderTableSmsHeaderColumns = () => {
        if (!expandedSms) {
            return null;
        }

        return (
            <>
                <th onClick={() => sortTable('sms.this_week')} className="table_sms sticky-row">This Week</th>
                <th onClick={() => sortTable('sms.this_month')} className="table_sms sticky-row">This Month</th>
                <th onClick={() => sortTable('sms.last_month')} className="table_sms sticky-row">Last Month</th>
            </>
        )
    }

    const renderTableMarketingHeaderColumns = () => {
        if (!expandedMarketing) {
            return null;
        }

        return (
            <>
                <th onClick={() => sortTable('marketing.sms')} className="table_marketing sticky-row">SMS Sent</th>
                <th onClick={() => sortTable('marketing.ppc')} className="table_marketing sticky-row">PPC</th>
                <th onClick={() => sortTable('marketing.email')} className="table_marketing sticky-row">Email</th>
                <th onClick={() => sortTable('marketing.social')} className="table_marketing sticky-row">FB Post</th>
                <th onClick={() => sortTable('marketing.push_notification')} className="table_marketing sticky-row">Push Notifications</th>
            </>
        )
    }

    const renderTableMarketingDataColumns = (v) => {
        if (!expandedMarketing) {
            return null;
        }
        return (
            <>
                <td className={'table_marketing ' + (v.marketing.sms ? 'marketingTrue' : 'marketingFalse')}></td>
                <td className={'table_marketing ' + (v.marketing.ppc ? 'marketingTrue' : 'marketingFalse')}></td>
                <td className={'table_marketing ' + (v.marketing.email ? 'marketingTrue' : 'marketingFalse')}></td>
                <td className={'table_marketing ' + (v.marketing.social ? 'marketingTrue' : 'marketingFalse')}></td>
                <td className={'table_marketing ' + (v.marketing.push_notification ? 'marketingTrue' : 'marketingFalse')}></td>
            </>
        )
    }

    
    const renderTableSmsDataColumns = (v) => {
        if (!expandedSms) {
            return null;
        }
        
        return (
            <>
                <td className="table_sms">{v.sms.this_week}</td>
                <td className="table_sms">{v.sms.this_month}</td>
                <td className="table_sms">{v.sms.last_month}</td>
            </>
        )
    }
    
    const renderTableSmsFooterColumns = (thisWeek, thisMonth, lastMonth) => {
        if (!expandedSms) {
            return null;
        }
        
        return (
            <>
                <td className="table_sms">{thisWeek}</td>
                <td className="table_sms">{thisMonth}</td>
                <td className="table_sms">{lastMonth}</td>
            </>
        )
    }
    
    const renderTableMarketingFooterColumns = (sms, ppc, email, facebook, push) => {
        if (!expandedMarketing) {
            return null;
        }
        return (
            <>
                <td>{sms}</td>
                <td>{ppc}</td>
                <td>{email}</td>
                <td>{facebook}</td>
                <td>{push}</td>
            </>
        )
    }

    const renderFooter = (shops) => {
        let totalOrders = 0;
        let totalOrdersToday = 0;
        let totalOrdersThisWeek = 0;
        let totalOrdersThisMonth = 0;
        let totalOrdersPrevMonth = 0;
        let totalOrdersPrevPrevMonth = 0;
        let totalOrdersPrevPrevPrevMonth = 0;
        let totalOrdersLastYearLastMonth = 0;
        let totalTrend = 0;

        let totalInstoreThisMonth = 0;
        let totalInstoreLastMonth = 0;
        let totalPhoneThisMonth = 0;
        let totalPhoneLastMonth = 0;
        let totalCardThisMonth = 0;
        let totalCardLastMonth = 0;

        let totalNumbers = 0;
        let totalNumbersLast7Days = 0;
        let totalNumbersThisMonth = 0;
        let totalNumbersLastMonth = 0;
        let totalNumbersCallerId = 0;
        let totalNumbersManuallyAdded = 0;

        let totalSMSBalance = 0;
        let totalSMSThisWeek = 0;
        let totalSMSThisMonth = 0;
        let totalSMSLastMonth = 0;

        let totalMarketingGoogle = 0;
        let totalMarketingSMS = 0;
        let totalMarketingEmail = 0;
        let totalMarketingPPC = 0;
        let totalMarketingFacebook = 0;
        let totalMarketingPush = 0;


        for(let s of shops ){
            totalOrders += s.orders.total;
            totalOrdersToday += s.orders.today;
            totalOrdersThisWeek += s.orders.this_week;
            totalOrdersThisMonth += s.orders.this_month;
            totalOrdersPrevMonth += s.orders.last_month;
            totalOrdersPrevPrevMonth += s.orders.prev_month;
            totalOrdersPrevPrevPrevMonth += s.orders.prev_prev_month;
            totalTrend += s.orders.last_month_trend;
            totalOrdersLastYearLastMonth += s.orders.last_month_last_year;

            totalInstoreThisMonth += s.orders_epos.instore_this_month;
            totalInstoreLastMonth += s.orders_epos.instore_last_month;
            totalPhoneThisMonth += s.orders_epos.phone_this_month;
            totalPhoneLastMonth += s.orders_epos.phone_last_month;
            totalCardThisMonth += s.orders_epos.card_terminal_payments_this_month;
            totalCardLastMonth += s.orders_epos.card_terminal_payments_last_month;

            totalNumbers += s.info.customers;
            totalNumbersLast7Days += s.numbers.last_7_days;
            totalNumbersThisMonth += s.numbers.this_month;
            totalNumbersLastMonth += s.numbers.last_month;
            totalNumbersCallerId += s.numbers.caller_id;
            totalNumbersManuallyAdded += s.numbers.added_manually;

            totalSMSBalance += s.sms.balance;
            totalSMSThisWeek += s.sms.this_week;
            totalSMSThisMonth += s.sms.this_month;
            totalSMSLastMonth += s.sms.last_month;

            totalMarketingGoogle += s.marketing.google_business;
            totalMarketingSMS += s.marketing.sms ? 1 : 0;
            totalMarketingEmail += s.marketing.email ? 1 : 0;
            totalMarketingPPC += s.marketing.ppc ? 1 : 0;
            totalMarketingFacebook += s.marketing.social ? 1 : 0;
            totalMarketingPush += s.marketing.push_notification ? 1 : 0;
        }

        let totalYoY = totalOrdersPrevMonth - totalOrdersLastYearLastMonth;

        return (<tfoot>
            <tr>
                <td className="headcol" style={{width: '50px', minWidth: '50px', maxWidth: '50px'}}>Totals:</td>
                <td className="headcol" style={{width: '50px', minWidth: '50px', maxWidth: '50px'}}></td>
                <td className="headcol" style={{width: '23px', minWidth: '23px', maxWidth: '23px'}}></td>
                <td className="headcol" style={{width: '23px', minWidth: '23px', maxWidth: '23px'}}></td>
                <td className="headcol2" style={{minWidth: "300px"}}></td>
                <td style={{width: '10px', minWidth: '10px', maxWidth: '10px'}}></td>
                {/* <td style={{width: '10px', minWidth: '10px', maxWidth: '10px'}}></td> */}
                <td>{sorted.filter(shop => moment(shop.machine.caller_id_last_heard && shop.machine.caller_id_last_heard?.customer_date_added) >= moment().subtract(2, 'd')).length} / {filtered.length}</td>

                <td>{totalInstoreThisMonth}</td>
                {renderTableEposOrdersFooters(totalInstoreLastMonth, totalPhoneThisMonth, totalPhoneLastMonth, totalCardThisMonth, totalCardLastMonth)}

                <td>{totalOrders}</td>
                {renderTableOrdersFooters(totalOrdersToday, totalOrdersThisWeek, totalOrdersThisMonth, totalTrend, totalYoY, totalOrdersPrevMonth, totalOrdersPrevPrevMonth, totalOrdersPrevPrevPrevMonth, totalOrdersLastYearLastMonth)}

                {renderShopInfoFooters()}

                <td>{totalNumbersLast7Days}</td>
                {renderTableNumbersFooterColumns(totalNumbersThisMonth, totalNumbersLastMonth, totalNumbers, totalNumbersCallerId, totalNumbersManuallyAdded)}

                <td>{totalSMSBalance}</td>
                {renderTableSmsFooterColumns(totalSMSThisWeek, totalSMSThisMonth, totalSMSLastMonth)}

                <td>{totalMarketingGoogle}</td>
                {renderTableMarketingFooterColumns(totalMarketingSMS, totalMarketingPPC, totalMarketingEmail, totalMarketingFacebook, totalMarketingPush)}
                <td></td>
            </tr>
        </tfoot>);
    }

    const {Agent = ''} = agent;
    const agentText = Agent !== '' ? `Shops For ${Agent}` : '';
    const titleText = title || agentText || 'Agent Shops';

    const tableNumbersImgSrc = expandedNumbers ? CollapsePurple : ExpandPurple;
    const tableMarketingImgSrc = expandedMarketing ? CollapseGrey : ExpandGrey;
    const tableSmsImgSrc = expandedSms ? CollapsePurple : ExpandPurple;
    const tableOrderImgSrc = expandedOrders ? Collapse : Expand;
    const tableShopInfoImgSrc = expandedShopInfo ? CollapseRed : ExpandRed;
    const tableEposOrdersImgSrc = expandedEposOrders ? Collapse : Expand;

    // Sort the dataset
    const sorted = orderBy(filtered, [shop => {
        let value;
        switch (sorter) {
            case 'device_type':
                value = getDeviceType(shop.machine.devices);
                break;

            case 'epos_status':
                value = renderEposState(shop, true);
                break;

            case 'YoY':
                value = shop.orders.last_month - shop.orders.last_month_last_year;
                break;

            case 'favourite_shop':
                value = Boolean(favouriteShops.includes(shop.wholesaler_id.toString()));
                break;

            case 'ranking':
                value = getRank(shop.wholesaler_rank_name, true);
                break;

            default:
                value = get(shop, sorter, 0);
        }

        // If the values are strings, make the sort case-insensitive
        if (typeof value === 'string') {
            return value.toLowerCase();
        }

        return value;
    }], [sortDir]);

    const handleShopFilterModalClose = (filterData) => {
        if(filterData !== undefined) {
            setShopFilterData(filterData);
        }
        filterClose();
        setFilterIsOpenLocal(false);
    }

    const renderSearchTerm = () => {
        if (searchTerm !== '') {
            return <AppliedFilterTag text={searchTerm} removeFilter={() => updateSearch('', '')} size={isMobile ? 'small' : 'medium'} />

        }
        return null;
    }
    function removeFilter(line) {
      var tempFilterData = {...shopFilterData};
      if (typeof line.type !== 'string') {
        line.type.forEach(filter => tempFilterData[filter] = line.default);
      } else {
        tempFilterData[line.type] = line.default;
      }
      return tempFilterData
    }

    const filterTag = line => (
      <div className="results-filter">
          <div>
              <div>{line.text}</div>
          </div>
          <div><img src={xLight} alt="x icon" onClick={() => setShopFilterData(removeFilter(line))}/></div>
      </div>
    )

    useEffect(() => {
      if (!sorted.length && !loading) localStorage.setItem(isMyRegion ? 'myRegionScrollPos' : 'myProblemShopsScrollPos', '0');
    }, [sorted, loading]);

    useEffect(() => {
      localStorage.setItem(isMyRegion ? 'myRegionFilters' : 'myProblemShopsFilters', JSON.stringify(shopFilterData));
      setFiltersEnabled(Object.values(shopFilterData).some(val => val !== -1 && !!val !== false));
    }, [shopFilterData])

    const renderShopFilterText = () => {
        const {hideGoogle = false, hideFacebook = false, hideSticker = false, onlyShowTemp = -1, onlyShowDevice = '', onlyFavourites = false} = shopFilterData;
        const missing = [] as Array<string>;
        const lines = [] as {text: string, type: string | string[], default: any}[];

        if (onlyShowDevice === 'T2s') lines.push({text: 'Show All - T2s', type: 'onlyShowDevice', default: ''});
        if (onlyShowDevice === 'V2') lines.push({text: 'Show All - V2', type: 'onlyShowDevice', default: ''});
        if (onlyShowDevice === 'Both') lines.push({text: 'Show All - T2s & V2', type: 'onlyShowDevice', default: ''});

        if (onlyFavourites) lines.push({text: 'Show All Favourites', type: 'onlyFavourites', default: false});

        if (onlyShowTemp === 6) lines.push({text: 'Show All - Temp Offline', type: 'onlyShowTemp', default: -1});
        if (onlyShowTemp === 100) lines.push({text: 'Show All - New Shops', type: 'onlyShowTemp', default: -1});
        if (onlyShowTemp === 2) lines.push({text: 'Show All - To Activate', type: 'onlyShowTemp', default: -1});
        if (onlyShowTemp === 5) lines.push({text: 'Show All - Cancelled', type: 'onlyShowTemp', default: -1});
        if (onlyShowTemp === 102) lines.push({text: 'Hide All - Orders Up From Last Month', type: 'onlyShowTemp', default: -1});
        if (onlyShowTemp === 101) lines.push({text: 'Hide All - Cancelled', type: 'onlyShowTemp', default: -1});
        if (onlyShowTemp === 103) lines.push({text: 'Hide All - Old Signups', type: 'onlyShowTemp', default: -1});

        if (hideSticker) missing.push('Sticker');
        if (hideGoogle) missing.push('G Listing');
        if (hideFacebook) missing.push('FB Page');

        if (missing.length) {
            lines.push({text: `Missing - ${missing.join(', ')}`, type: ['hideSticker', 'hideGoogle', 'hideFacebook'], default: false});
        }

        if (lines.length) {
            return (
              lines.map((line, index) => (
                <AppliedFilterTag text={line.text} removeFilter={() => setShopFilterData(removeFilter(line))} size={isMobile ? 'small' : 'medium'} />
              ))
            )
        }

        return null;
    }

    return (
        <div id="dashboard-actual" className={`${filtersEnabled || searchTerm ? 'has-filters' : ''} ${!sorted.length ? 'empty' : ''}`}>
            <ShopFilter
                isOpen={filterIsOpen || filterIsOpenLocal}
                setClosed={handleShopFilterModalClose}
                data={shopFilterData}
            />

            {searchOpen &&
              <div className='search-wrapper'>
                <SearchField
                  onSearch={val => {updateSearch(val, ''); setSearchOpen(false)}}
                  placeholder="Search name or postcode"
                  closeField={() => setSearchOpen(false)} />
              </div>
            }

            {isMobile ? (
                <div className={`header ${(filtersEnabled || searchTerm) && sorted.length ? 'header--fixed' : ''}`}>
                    <div className={`sticky-header ${filtersEnabled || searchTerm ? 'sticky-header--fixed' : ''}`}>
                        {renderTitle()}
                <div className='filter-tag-wrapper'>
                  {renderSearchTerm()}
                  {renderShopFilterText()}
                </div>
                    </div>
                    <Disclaimer />
                </div>
            ) : (
                <div className='filter-tag-wrapper'>
                  {renderSearchTerm()}
                  {renderShopFilterText()}
                </div>
            )}
            {loading ?
                <div className="spinner-border">
                    <PuffLoader
                        size={75}
                        color={"#D82B31"}
                        loading={true}
                    />
                </div>
                :
                <div className="wrapper">
                    {sorted.length === 0 ? (
                      <div className='empty-wrapper'>
                        <EmptyTable type={!shops.length ? 'none-assigned' : 'filter'} tableType='shops' />
                      </div>
                    ) :
                        <table className={`table ${filtersEnabled || searchTerm ? 'table--has-filters' : ''}`}>
                            <thead>
                            <tr className={filtersEnabled || searchTerm ? 'row-has-filters' : ''}>
                                <th onClick={() => sortTable('device_type')} className="headcol sticky-row"
                                    style={{width: '50px', minWidth: '50px', maxWidth: '50px'}}>Device
                                </th>
                                <th onClick={() => sortTable('wholesaler_id')} className="headcol sticky-row"
                                    style={{width: '50px', minWidth: '50px', maxWidth: '50px'}}>ID
                                </th>
                                <th onClick={() => sortTable('ranking')} className="headcol sticky-row"
                                    style={{width: '23px', minWidth: '23px', maxWidth: '23px'}}>
                                      <img src={RankIcon} alt="Rank Icons" className='rank-icon' />
                                </th>
                                <th onClick={() => sortTable('favourite_shop')} className="headcol sticky-row"
                                    style={{width: '23px', minWidth: '23px', maxWidth: '23px'}}>Fav
                                </th>
                                <th className="headcol2 sticky-row" style={{minWidth: "300px"}}>
                                    <div className="flex-basic">
                                        <span onClick={() => sortTable('wholesaler_name')}>Shop Name</span>
                                        <span onClick={() => sortTable('wholesaler_postcode')}>Postcode</span>
                                    </div>
                                </th>
                                <th onClick={() => sortTable('wholesaler_status')} className="table_epos_status sticky-row"
                                    style={{width: '10px', minWidth: '10px', maxWidth: '10px'}}>
                                        <span className="dot dot--green"></span>
                                </th>

                                <th onClick={() => sortTable('epos_status')} className="table_epos_status sticky-row">Caller ID</th>

                                <th className="table_epos_orders toggler sticky-row">
                                    <div className="toggler-inner">
                                        <div onClick={() => sortTable('orders_epos.instore_this_month')} className="toggler-left">
                                            <div className="header-badge-wrapper">
                                                <div className="header-badge header-badge--epos grey-badge">
                                                    <div className="badge-text badge-text--epos">ePOS</div>
                                                </div>
                                                <div className="header-badge white-badge">
                                                    <div className="badge-text">In-Store</div>
                                                </div>
                                            </div>
                                                <div className="whiteText">This Month</div>
                                        </div>
                                        <div className="toggler-right"
                                            onClick={() => setExpandedEposOrders(!expandedEposOrders)}>
                                            <img src={tableEposOrdersImgSrc} alt="back" className="toggler-back"/>
                                        </div>
                                    </div>
                                </th>
                                {renderTableEposOrdersHeaders()}

                                <th className="table_orders toggler sticky-row">
                                    <div className="toggler-inner">
                                        <div onClick={() => sortTable('orders.total')} className="toggler-left">
                                            <div className="header-badge pink-badge">
                                                <div className="badge-text">Orders</div>
                                            </div>
                                            <div className="whiteText">Total</div>
                                        </div>
                                        <div className="toggler-right"
                                             onClick={() => setExpandedOrders(!expandedOrders)}>
                                            <img src={tableOrderImgSrc} alt="back" className="toggler-back"/>
                                        </div>
                                    </div>
                                </th>
                                {renderTableOrdersHeaders()}

                                <th className="table_shop_info toggler sticky-row">
                                    <div className="toggler-inner">
                                        <div onClick={() => sortTable('info.label')} className="toggler-left">
                                            <div className="header-badge red-badge">
                                                <div className="badge-text">Info</div>
                                            </div>
                                            <div className="redText">Status</div>
                                        </div>
                                        <div className="toggler-right"
                                            onClick={() => setExpandedShopInfo(!expandedShopInfo)}>
                                            <img src={tableShopInfoImgSrc} alt="back" className="toggler-back"/>
                                        </div>
                                    </div>
                                </th>
                                {renderTableShopInfoHeaders()}

                                <th className="table_numbers toggler sticky-row">
                                    <div className="toggler-inner">
                                        <div onClick={() => sortTable('numbers.last_7_days')} className="toggler-left">
                                            <div className="header-badge purple-badge">
                                                <div className="badge-text">Numbers</div>
                                            </div>
                                            <div className="purpleText">Last 7 Days</div>
                                        </div>
                                        <div className="toggler-right"
                                             onClick={() => setExpandedNumbers(!expandedNumbers)}>
                                            <img src={tableNumbersImgSrc} alt="back" className="toggler-back"/>
                                        </div>
                                    </div>
                                </th>
                                {renderTableNumbersHeaderColumns()}


                                <th className="table_sms toggler sticky-row">
                                    <div className="toggler-inner">
                                        <div onClick={() => sortTable('sms.balance')} className="toggler-left">
                                            <div className="header-badge purple-badge">
                                                <div className="badge-text">SMS</div>
                                            </div>
                                            <div className="purpleText">Balance</div>
                                        </div>
                                        <div className="toggler-right" onClick={() => setExpandedSms(!expandedSms)}>
                                            <img src={tableSmsImgSrc} alt="back" className="toggler-back"/>
                                        </div>
                                    </div>
                                </th>
                                {renderTableSmsHeaderColumns()}

                                <th className="table_marketing toggler sticky-row">
                                    <div className="toggler-inner">
                                        <div onClick={() => sortTable('marketing.google_business')} className="toggler-left">
                                            <div className="header-badge yellow-badge">
                                                <div className="badge-text">Marketing</div>
                                            </div>
                                            <div>G Listing</div>
                                        </div>
                                        <div className="toggler-right" onClick={() => setExpandedMarketing(!expandedMarketing)}>
                                            <img src={tableMarketingImgSrc} alt="back" className="toggler-back"/>
                                        </div>
                                    </div>
                                </th>
                                {renderTableMarketingHeaderColumns()}

                                <th onClick={() => sortTable('promotion.google')} className="sticky-row promo_col">Promo</th>
                            </tr>
                            </thead>
                            <tbody>
                            {sorted?.map((v, i) => {
                                return (
                                <tr key={i} className={getStatus('class', parseInt(v.info.wholesaler_status))}>
                                    <td className="headcol" style={{
                                        width: '50px',
                                        minWidth: '50px',
                                        maxWidth: '50px'
                                    }}>{getDeviceType(v.machine.devices)}</td>
                                    <td className="headcol" style={{
                                        width: '50px',
                                        minWidth: '50px',
                                        maxWidth: '50px'
                                    }}>{v.wholesaler_id}</td>
                                    <td className="headcol" style={{
                                          width: '23px',
                                          minWidth: '23px',
                                          maxWidth: '23px',
                                          padding: 0
                                    }}><img src={getRank(v.wholesaler_rank_name, false)} alt='Shop rank icon' className='rank-icon' /></td>
                                    <td className="headcol headcol--favourites"
                                        onClick={() => handleFavouriteclick(v.wholesaler_id.toString())}
                                        style={{
                                          width: '23px',
                                          minWidth: '23px',
                                          maxWidth: '23px',
                                          padding: 0
                                        }}>
                                        {favouriteShops.includes(v.wholesaler_id.toString()) ? (
                                            <img src={FavouriteActive} alt="Favourite shop" />
                                        ) : (
                                          <img src={FavouriteInactive} alt="Shop not favourited" />
                                        )}

                                  </td><td className="headcol2">
                                      <div className="flex-basic">
                                        <span style={{ maxWidth: "200px", textOverflow: 'ellipsis', overflow: 'hidden' }}
                                          onClick={() => openOverview(v)}>{unescape(v.wholesaler_name)}</span><span
                                            style={{
                                              paddingLeft: 12,
                                              textTransform: 'uppercase'
                                            }}>{v.wholesaler_postcode}</span>
                                      </div>
                                    </td><td style={{
                                      width: '10px',
                                      minWidth: '10px',
                                      maxWidth: '10px'
                                    }}>
                                      <span className={getDotClass(v.info.wholesaler_status)}></span>
                                    </td>

                                        {renderEposState(v)}

                                        <td>{v.orders_epos.instore_this_month}</td>
                                        {renderTableEposOrdersData(v)}

                                        <td>{v.orders.total}</td>
                                        {renderTableOrdersData(v)}

                                        <td style={disallowedStatusCodes.includes(parseInt(v.info.wholesaler_status)) ? {color: '#D82B31', fontWeight: 600} : {}}>
                                            {getStatus('tableCell', parseInt(v.info.wholesaler_status))}
                                        </td>

                                        {renderTableShopInfoData(v)}

                                        <td>{v.numbers.last_7_days}</td>
                                        {renderTableNumbersDataColumns(v)}

                                        <td>{v.sms.balance}</td>
                                        {renderTableSmsDataColumns(v)}

                                        <td className={"table_marketing " +
                                            (v.marketing.google_business === 0 ? 'marketingFalse' :
                                            v.marketing.google_business === 2 ? 'marketingPending' : 'marketingTrue' )}></td>
                                        {renderTableMarketingDataColumns(v)}

                                        <td className="promo_col">
                                            <img className={"promo_icon " + (v.promotion.facebook ? '' : 'icon-off')}
                                                 src={!v.promotion.facebook ? Facebook : FacebookOn} alt="FB Svg"/>
                                            <img className={"promo_icon " + (v.promotion.google ? '' : 'icon-off')}
                                                 src={!v.promotion.google ? Google : GoogleOn} alt="Google Svg"/>
                                            <img className={"promo_icon " + (v.promotion.stickers ? '' : 'icon-off')}
                                                 src={!v.promotion.stickers ? Sticker : StickerOn} alt="Sticker Svg"
                                                 style={{fill: "red"}}/>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                            {renderFooter(sorted)}
                        </table>
                    }
                </div>
            }
        </div>
    );
}

export default AgentShopList;
