import moment from "moment";
import './index.scss';
import InfoTag from "../../../info-tag";
import AddedIconThali from "../../../../img/addedIconNewThali.svg";
import NoteOutline from '../../../../img/icons/noteOutlineThali02.svg';
import Tag from "../../../tag";
import PropTypes from 'prop-types';
import GreenTickFilled from "../../../../img/greenTickFilled.svg";
import EditIcon from "../../../../img/editIcon.svg";
import { getName } from '../../../../assigneeData';
import { useSelector } from 'react-redux';

interface Props {
  createdBy?: string
  assignee?: string
  date: string
  description?: string
  priority?: string
  type?: string
}

const TimelineEntry = ({ createdBy, assignee, date, description, priority, type }: Props) => {
  const staffList = useSelector((state: any) => state.staff);


  const createdByTag = (createdBy) => (
    <Tag text={createdBy ? createdBy.toUpperCase() : 'UNKNOWN'} type={(type === "createdOld" || type === "created") ? "highlight" : "creme-brulee"} />
  )

  const assigneeTag = (assignee) => (
    <Tag text={assignee ? assignee.toUpperCase() : 'UNASSIGNED'} type="creme-brulee" />
  )

  const priorityTag = (priority) => (
    <Tag priority={priority?.toLowerCase()} />
  )

  return (
    <div className="timeline-entry">

      {date && <span className="timeline-entry__log-date">{moment(date).format('DD/MM/YYYY')}</span>}

      {type === 'createdOld' && (
        <>
          <InfoTag icon={AddedIconThali} iconAltText="Added Icon" text="Created Job" tag1={createdByTag(createdBy)} />
          {assignee && <InfoTag text="Assigned Job to" tag1={createdByTag(createdBy)} tag2={assigneeTag(assignee)} spacer />}
          {priority && <InfoTag text="Set Priority as" tag1={createdByTag(createdBy)} tag2={priorityTag(priority)} spacer />}
        </>
      )}

      {type === 'created' && (
        <>
          <InfoTag icon={AddedIconThali} iconAltText="Added Icon" text="Created Job" tag1={createdByTag(createdBy)} />
          {assignee && <InfoTag text="Assigned Job to" tag1={createdByTag(createdBy)} tag2={assigneeTag(getName(staffList, assignee))} spacer />}
          {priority && <InfoTag text="Set Priority as" tag1={createdByTag(createdBy)} tag2={priorityTag(priority)} spacer />}
        </>
      )}

      {type !== 'updated' && type !== 'completed' && description && (
        <>
          <InfoTag icon={NoteOutline} iconAltText={"Note Icon"} text={"Left Note"} tag1={createdByTag(createdBy)} />
          <p className="timeline-entry__log-note">{description}</p>
        </>
      )}

      {type === 'updated' && (
        <>
          {assignee && <InfoTag icon={EditIcon} iconAltText={"Edit Icon"} text="Assigned Job to" tag1={createdByTag(createdBy)} tag2={assigneeTag(getName(staffList, assignee))} />}
          {assignee && priority && <InfoTag text="Set Priority as" tag1={createdByTag(createdBy)} tag2={priorityTag(priority)} spacer />}
          {!assignee && priority && <InfoTag icon={EditIcon} iconAltText={"Edit Icon"} text="Set Priority as" tag1={createdByTag(createdBy)} tag2={priorityTag(priority)} />}
        </>
      )}

      {type === 'completed' && (
        <>
          <InfoTag icon={GreenTickFilled} iconAltText={"Completed Icon"} text={"Closed Job"} tag1={createdByTag(createdBy)} />
          <p className="timeline-entry__log-note">{description}</p>
        </>
      )}

    </div>
  )
}

TimelineEntry.propTypes = {
  type: PropTypes.oneOf(['createdOld', 'created', 'updated', 'completed']),
}

export default TimelineEntry;