import RankTag from '../../rank-tag';
import './index.scss';
import fmoLogo from '../../../img/logoAlt.svg';
import PropTypes from 'prop-types';

type Props = {
  value: number | string
  heading: string
  type?: string
} & (
  {
    joined?: boolean
    rank: string | undefined
    platformCard?: never
  } | {
    joined?: never
    rank?: never
    platformCard: boolean
  }
)

const StatCard = ({value, heading, type, joined=false, platformCard=false, rank}: Props) => (
  <div className={`stat-card ${type ? `stat-card--${type}` : ''} ${platformCard ? `stat-card--platform-card` : ''}`}>
    <div className={`stat-card__heading ${type ? `stat-card__heading--${type}` : ''}`}>{heading}</div>
    <div className={`stat-card__value ${type ? `stat-card__value--${type}` : ''}`}>{type === 'disabled' ? 'N/a' : value}</div>
    {!platformCard && <div className='stat-card__tag-wrapper'>
      {joined && <img src={fmoLogo} alt='Shop joined this month' className='stat-card__joined-logo' />}
      <RankTag rank={rank} />
    </div>}
  </div>
)

StatCard.propTypes = {
  type: PropTypes.oneOf(['warning', 'disabled']),
}

export default StatCard;